import styles from 'assets/scss/components/footer.module.scss';
import classNames from 'classnames/bind';
import DeseretNewsLogo from 'public/images/svg/deseret_news_logo.svg';

export default function Footer() {
   return <footer className={classNames(styles.footerWrapper,'row', 'mt-auto')} data-cy={'footer_footerWrapper'}>
       <div className={classNames("container", styles.navContainer)}>
           <div className="row">
               <div className="col-12 col-md-8">
                   <div className="row mt-4">
                       <div className="col-12 text-center text-md-start">
                           <a rel="nofollow" href="https://www.deseret.com/">
                               <DeseretNewsLogo className={styles.deseretNewsLogo} width={200}/>
                           </a>
                       </div>
                   </div>
                   <div className="row mt-4">
                       <div className="col-12 text-center text-md-start">
                           <ul className={styles.footerLinkWrapper} data-cy={'footerLinkWraper'}>
                               <li>
                                   <a href="https://www.deseret.com/legal/terms-of-use">Terms of Use</a>
                               </li>
                               <li>
                                   <a href="https://www.deseret.com/legal/privacy-policy">Privacy Policy</a>
                               </li>
                               <li>
                                   <a href="https://www.deseret.com/legal/cookie-policy">Cookie Policy</a>
                               </li>
                               <li>
                                   <a href="#" onClick={(evt) => { evt.preventDefault(); }} className={'ot-sdk-show-settings'} suppressHydrationWarning>Cookie Settings</a>
                               </li>
                               </ul>
                           </div>
                       </div>
                       <div className="row mt-2">
                           <div className="col-12 text-center text-md-start">
                               <ul className={styles.footerLinkWrapper} data-cy={'footerLinkWraper'}>
                                   <li className="">
                                       <a href="https://www.deseret.com/pages/about-us" target="">About Us</a>
                                   </li>
                                   <li className="">
                                       <a href="https://www.deseret.com/pages/contact-us" target="">Contact Us</a>
                                   </li>
                                   <li className="">
                                       <a href="https://www.deseret.com/contact" target="">Customer Support</a>
                                   </li>
                                   <li className="">
                                       <a href="https://deseretmanagement.wd1.myworkdayjobs.com/DeseretNews" target="">Jobs/Careers</a>
                                   </li>
                                   <li className="">
                                       <a href="https://www.deseret.com/contact#tip" target="">Send Us a Tip</a>
                                   </li>
                                   <li className="">
                                       <a href="https://www.deseret.com/archives/full" target="">View Archive</a>
                                   </li>
                               </ul>
                           </div>
                       </div>
                   </div>
                   <div className="col-12 col-md-4">
                       <div className="d-flex justify-content-center justify-content-md-end pt-3 pt-md-0 align-items-end h-100">
                            <a href="/high-school/feedback" className={styles.feedbackLink}>
                                <img src="/images/svg/icon_feedback.svg" />
                                <p className="pt-2">Send Us Feedback</p>
                            </a>
                       </div>
                   </div>
               </div>
               <div className={classNames('row', styles.footerCopyright, 'mt-2', 'py-2')}>
                   <div className="col-12 text-center text-md-end">
                       Copyright © {new Date().getFullYear()} Deseret News Publishing Company. All Rights Reserved
                   </div>
               </div>
           </div>
       </footer>;
}
