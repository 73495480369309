import { AdSlot, DFPSlotsProvider } from "react-dfp";
import { useSelector } from "react-redux";
import { useRef, useState, useEffect } from "react";
import styles from "assets/scss/components/DfpAd.module.scss";

const DfpAd = ({
    mobileSizes,
    desktopSizes,
    targeting,
    largestSizeForContainer = false,
    additionalFittingSizes = [],
    fallbackSmallest = true
}) => {
    const [overrideSizes, setOverrideSizes] = useState([]);
    const adRef = useRef();
    useEffect(() => {
        if (largestSizeForContainer && adRef.current && adRef.current.adElementRef.current) {
            const adEl = adRef.current.adElementRef.current;
            const containerWidth = adEl.parentElement.offsetWidth;

            const fittingSizes = [];
            if (Array.isArray(additionalFittingSizes) && additionalFittingSizes.length) {
                for (const size of additionalFittingSizes) {
                    if (size[0] <= containerWidth) {
                        fittingSizes.push(size);
                    }
                }
            }

            const allSizes = [...(desktopSizes || []), ...(mobileSizes || [])];
            let largestFitting = null;
            let fallback = null;
            for (const size of allSizes) {
                if (Array.isArray(size) && size.length === 2) {
                    if (size[0] <= containerWidth) {
                        if (largestFitting === null) {
                            largestFitting = size;
                        } else if (largestFitting[0] < size[0]) {
                            largestFitting = size;
                        }
                    }
                    if (fallback === null || size[0] < fallback[0]) {
                        fallback = size;
                    }
                }
            }
            const selectedSize = largestFitting || (fallbackSmallest ? fallback : null);
            if (selectedSize) {
                fittingSizes.push(selectedSize);
            }

            const uniqueFittingSizes = [...new Set(fittingSizes.map(JSON.stringify))].map(JSON.parse);
            setOverrideSizes(uniqueFittingSizes);
        }
    }, [
        JSON.stringify([
            largestSizeForContainer,
            additionalFittingSizes,
            fallbackSmallest,
            !!adRef.current && !!adRef.current.adElementRef.current,
        ])
    ]);

    const isMobile = useSelector(state => state.isMobile);

    const adSizes = overrideSizes.length ? overrideSizes : (isMobile ? mobileSizes : desktopSizes);

    return <div className={styles.dfpad} data-cy="Ad-space">
        <DFPSlotsProvider dfpNetworkId="22653237939" lazyLoad={true}>
            <AdSlot
                ref={adRef}
                adUnit="deseret/www.deseret.com/sports/high-school"
                sizes={adSizes}
                targetingArguments={targeting}
            />
        </DFPSlotsProvider>
    </div>
};

export default DfpAd;
