import dynamic from 'next/dynamic'
import Head from "next/head";
import Footer from "components/footer";
import CookiePro from "components/CookiePro";
import {useLayoutEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {MOBILE_WIDTH} from "lib/consts";
import styles from "assets/scss/components/layout/BaseLayout.module.scss";
import classNames from 'classnames';
import {throttle} from 'lodash';
import DfpAd from "components/DfpAd";
import {isFunction} from "lodash";
const HeaderComponent = dynamic(() => import('components/header'), {
    ssr : false,
    loading: () => <div className={styles.headerSpace} data-cy="Head-space"></div>
});

export default function BaseLayout({
    title,
    description = null,
    sportNavigations,
    navSchools,
    headers,
    canonicalUrl = null,
    preContent,
    content,
    mobileContentTop = null,
    mobileContentTopClass = null,
    contentId,
    contentClass = null,
    showSearchWidget = false,
    sidebar = true,
    schemaDotOrgData = null,
    loadForApp = false,
    cookieproDomainId = ''
}) {
    const currentIsMobile = useSelector(state => state.isMobile);
    const dispatch = useDispatch();

    function isMobileCheck() {
        if (typeof window === 'undefined') {
            return;
        }

        let isMobile = window.innerWidth < MOBILE_WIDTH;
        if (isMobile !== currentIsMobile) {
            // Also check for previous so we don't spam the state causing a re-render every time we resize //
            dispatch({
                type: 'IS_MOBILE',
                isMobile
            });
        }
    }

    if (typeof window !== 'undefined') {
        // If we don't have a canonical passed in, we want to set it to the URL they are visiting. This
        // occurring should be rare

        if (!canonicalUrl) {
            canonicalUrl = window.location.href;
        }

        useLayoutEffect(() => {
            const throttledMobileCheck = throttle(isMobileCheck, 200);
            //trigger check on load
            throttledMobileCheck();

            window.addEventListener('resize', throttledMobileCheck);
            window.addEventListener('orientationchange', throttledMobileCheck);

            return () => {
                window.removeEventListener('resize', throttledMobileCheck);
                window.removeEventListener('orientationchange', throttledMobileCheck);
            }
        })
    }

    const contentWithSidebar = () => {
        return <div className={classNames('container mt-3 px-0 px-lg-3', contentClass)} id={contentId}>
            <div className="row">
                <div className="col-12 col-lg-9 pb-5">
                    {mobileContentTop && <div className={classNames('mobileContentTop', mobileContentTopClass)}>{mobileContentTop}</div>}
                    {isFunction(content) ? content() : content}
                </div>
                <div className="col-12 col-lg-3" data-cy="side-bar">
                    <DfpAd
                        desktopSizes={[[300,250]]}
                        mobileSizes={[]}
                        targeting={{
                            pos: "right",
                            environment: process.env.APP_ENV || 'prod',
                            site: "hs-sports"
                        }}
                    />
                    { isFunction(sidebar) ? sidebar() : typeof sidebar !== 'boolean' ? sidebar : null }
                </div>
            </div>
        </div>
    }

    const contentWithoutSidebar = () => {
        return <div className={classNames('container mt-4 px-0 px-lg-3', contentClass)} id={contentId} data-cy={'container'}>
            <div className="row">
                <div className="col-12 pb-5">
                    {mobileContentTop && <div className={classNames('mobileContentTop', mobileContentTopClass)}>{mobileContentTop}</div>}
                    {isFunction(content) ? content() : content}
                </div>
            </div>
        </div>
    }

    return (
        <div className={classNames('container-fluid', styles.pageWrapper)}>
            <Head>
                <CookiePro domainId={cookieproDomainId} hideBanner={loadForApp} />
                <title>{isFunction(title) ? title('page') : title}</title>
                <link rel="stylesheet" href="https://use.typekit.net/zwt2mhd.css"></link>
                <meta name="description" content={isFunction(description) ? description() : description}/>
                <link rel="shortcut icon" href="https://cdn.vox-cdn.com/uploads/chorus_asset/file/18298160/favicon.0.jpeg" />
                <link rel="icon" type="image/png" href="https://cdn.vox-cdn.com/uploads/chorus_asset/file/18298163/favicon-16x16.0.png" sizes="16x16" />
                <link rel="icon" type="image/png" href="https://cdn.vox-cdn.com/uploads/chorus_asset/file/18298166/favicon-32x32.0.png" sizes="32x32" />
                <link rel="icon" type="image/png" href="https://cdn.vox-cdn.com/uploads/chorus_asset/file/18298161/favicon-96x96.0.png" sizes="96x96" />
                <link rel="icon" type="image/png" href="https://cdn.vox-cdn.com/uploads/chorus_asset/file/21958854/deseret-192x192.0.png" sizes="192x192" />
                <link rel="apple-touch-icon" href="https://cdn.vox-cdn.com/uploads/chorus_asset/file/21958841/deseret-180x180.0.png" sizes="180x180" />

                <meta name="msapplication-TileImage" content="https://cdn.vox-cdn.com/uploads/chorus_asset/file/18298164/favicon-144x144.0.png" />
                <meta name="msapplication-TileColor" content="#979797" />
                <meta name="theme-color" content="" />

                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="application-name" content="Deseret News High School Sports"/>
                <meta name="msapplication-square310x310logo" content={"/mstile-310x310.png"} />
                <link rel="stylesheet" href="https://use.typekit.net/yqy4szw.css" />
                <link rel="canonical" href={canonicalUrl}/>

                <link rel="preload" href="https://fonts.voxmedia.com/unison/deseret/benton_sans_regular/Benton_Sans_Regular.woff2" as="font" type="font/woff2" crossOrigin=""/>
                <link rel="preload" href="https://fonts.voxmedia.com/unison/deseret/benton_sans_bold/Benton_Sans_Bold.woff2" as="font" type="font/woff2" crossOrigin=""/>
                <link rel="preload" href="https://fonts.voxmedia.com/unison/deseret/benton_sans_condensed_medium/Benton_Sans_Condensed_Medium.woff2" as="font" type="font/woff2" crossOrigin=""/>
                <link rel="preload" href="https://fonts.voxmedia.com/unison/deseret/poynter_oldstyle_display_bold/Poynter_Oldstyle_Display_Bold.woff2" as="font" type="font/woff2" crossOrigin=""/>
                <link rel="preload" href="https://fonts.voxmedia.com/unison/deseret/poynter_oldstyle_text_italic/Poynter_Oldstyle_Text_Italic.woff2" as="font" type="font/woff2" crossOrigin=""/>
                <link rel="preload" href="https://fonts.voxmedia.com/unison/deseret/poynter_oldstyle_text_roman/Poynter_Oldstyle_Text_Roman.woff2" as="font" type="font/woff2" crossOrigin=""/>
                <link rel="preload" href="https://fonts.voxmedia.com/unison/deseret/poynter_oldstyle_text_bold/Poynter_Oldstyle_Text_Bold.woff2" as="font" type="font/woff2" crossOrigin=""/>

                <meta property="og:url" content={canonicalUrl}/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Deseret News"/>
                <meta property="og:title" content={isFunction(title) ? title('opengraph') : title}/>
                <meta property="og:description" content={isFunction(description) ? description() : description}/>
                <meta property="og:image" content="https://uploads.deseret.com/hs-sports/image_share_lg.jpg"/>
                <meta property="og:image:secure_url" content="https://uploads.deseret.com/hs-sports/image_share_lg.jpg"/>
                <meta property="og:image:type" content="image/jpeg"/>
                <meta property="og:image:width" content="1200"/>
                <meta property="og:image:height" content="630"/>
                <meta property="og:image:alt" content="Deseret News High School Sports logo"/>

                <meta property="fb:app_id" content="549923288395304"/>
                <meta name="twitter:site" content="@DeseretNews"/>
                <meta name="twitter:card" content="summary"/>

                <link rel="alternate" type="application/rss+xml" title="High School Sports" href="https://www.deseret.com/rss/high-school-sports/index.xml"/>

                <script
                    id="charbeat"
                    async
                    dangerouslySetInnerHTML={{
                        __html: `(function() {
                    var _sf_async_config = window._sf_async_config = (window._sf_async_config || {});
                    _sf_async_config.uid = 21057;
                    _sf_async_config.domain = 'deseret.com';
                    _sf_async_config.useCanonical = true;
                    _sf_async_config.useCanonicalDomain = true;
                    _sf_async_config.sections = '';
                    _sf_async_config.authors = '';
                    })();`,
                    }}
                />
                <script
                    {...(cookieproDomainId ? { type: 'text/plain', className: 'optanon-category-C0002' } : {})}
                    async
                    src="https://static.chartbeat.com/js/chartbeat.js"
                />

                {schemaDotOrgData ? (
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaDotOrgData,undefined,4) }}
                    />
                ) : null}
            </Head>
            <div className={styles.contentWrapper} data-cy={'contentWrapper'}>
                {!loadForApp ?
                    <>
                        <HeaderComponent sportNavigations={sportNavigations} navSchools={navSchools} showSearchWidget={showSearchWidget} />
                        { headers }
                    </>
                    : null
                }
                <div className={styles.smallScreen}>
                    <DfpAd
                        largestSizeForContainer={true}
                        additionalFittingSizes={[[728, 90]]}
                        desktopSizes={[[1280, 100], [970, 66], [728, 90]]}
                        mobileSizes={[[320, 50]]}
                        targeting={{
                            pos: "top",
                            environment: process.env.APP_ENV || 'prod',
                            site: "hs-sports"
                        }}
                    />
                </div>
                { preContent }
                { sidebar ? contentWithSidebar() : contentWithoutSidebar() }
                <DfpAd
                    largestSizeForContainer={true}
                    desktopSizes={[[728, 90]]}
                    mobileSizes={[[300, 250], [320, 50], [300, 50]]}
                    targeting={{
                        pos: "bottom",
                        environment: process.env.APP_ENV || 'prod',
                        site: "hs-sports"
                    }}
                />
                {!loadForApp ?
                    <Footer/> :
                    null
                }
            </div>
        </div>
    );
}
