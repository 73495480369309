export default function CookiePro({ domainId, hideBanner = false, lang = 'en' }) {
    const cookieProScriptInline = `
        // Init gtag and set consent defaults
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        // GDPR/GDPR-like default
        gtag('consent', 'default', {
            ad_storage: 'denied',
            analytics_storage: 'denied',
            wait_for_update: 500,
            region: [
                'NO', 'DE', 'FI', 'BE', 'PT', 'BG', 'DK', 'LT', 'LU', 'HR', 'LV', 'FR', 'HU', 'BR', 'SE', 'MC', 'SI', 'SK', 'MF', 'SM', 'GB', 'YT', 'IE', 'GF', 'EE', 'MQ', 'MT', 'GP', 'IS', 'IT', 'GR', 'ES', 'RE', 'AT', 'CY', 'CZ', 'AX', 'PL', 'RO', 'LI', 'NL'
            ]
        });
        // CCPA/CCPA-like default
        gtag('consent', 'default', {
            ad_storage: 'granted',
            analytics_storage: 'granted',
            wait_for_update: 500,
            region: [
                'US-OR', 'US-CA', 'US-WA', 'US-NH', 'US-FL', 'US-CO', 'US-NV', 'US-MT', 'US-IL', 'US-DE', 'US-CT', 'US-PA', 'US-VA', 'US-MA', 'US-MD'
            ]
        });
        // Everywhere else
        gtag('consent', 'default', {
            ad_storage: 'granted',
            analytics_storage: 'granted'
        });
    ` + (hideBanner ? `
        function OptanonWrapper() {
            const consentContainer = document.querySelector('#onetrust-consent-sdk');
            const isBannerClosed = OneTrust.IsAlertBoxClosed();
            if (consentContainer && !isBannerClosed) {
                // Hide banner without setting consent preferences
                consentContainer.style.display = 'none';
            }
        }
    ` : `
        function OptanonWrapper() {}
    `);

    return domainId ? (
        <>
            {/* eslint-disable-next-line react/no-danger */}
            <script dangerouslySetInnerHTML={{ __html: cookieProScriptInline }} />

            {/* CookiePro */}
            {/* eslint-disable-next-line @next/next/no-sync-scripts */}
            <script
                src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
                type="text/javascript"
                data-language={lang}
                data-domain-script={domainId}
            />
            {/* /CookiePro */}
        </>
    ) : null;
}
